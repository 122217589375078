import React from 'react'
import {useFormik} from "formik";
import {ModifyUserSchema} from "../../validationSchema/validationSchema";
import {useTranslation} from 'react-i18next'
import {Card, Button, Row, Col, Form,} from "react-bootstrap";
import {modifyUser} from "../../redux/action";
import {useDispatch} from "react-redux";
import {useFetchUserProfileQuery} from "../../redux/queryAPI/customerWebsiteAPI";
import {ReactComponent as ArrowDownIcon} from "../../assets/img/arrow-down.svg";
import {toast} from "react-toastify";

const UserProfile = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { data, error, isLoading } = useFetchUserProfileQuery();

    const languages = {
        SPANISH: {
            nativeName: 'Español'
        },
        ENGLISH: {
            nativeName: 'English'
        },
    };

    const formik = useFormik({
        initialValues: {
            id: data?.userId,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            dataFormat: data?.dataFormat,
            language: data?.language || 'ENGLISH', // Valor por defecto
            phone: data?.phone,
            role: data?.role,
            organizationId: data?.organizationId,
            organizationName: data?.organizationName,
            partyGroupId: data?.partyGroupId,
        },
        validationSchema: ModifyUserSchema,
        onSubmit: async (values) => {
            try {
                await dispatch(
                    modifyUser(
                        values.id, values.firstName, values.lastName, values.email, values.dataFormat,
                        values.language, values.phone, values.role, values.organizationId, values.organizationName, values.partyGroupId
                    )
                );
                await i18n.changeLanguage(values.language);
            } catch (e) {
                toast.error('Se ha producido un error al cambiar de idioma', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    theme: "light",
                });
            }
        }
    });

    if (isLoading) return <div>Loading...</div>
    if (error) {
        return (
            <div>Error: {error}</div>
        )
    }

    const {errors, touched} = formik;

    return (
        <React.Fragment>
            <Card>
                <Card.Body className="p-4">
                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-4">
                                <Form.Label className="font-size-13 font-weight-bold">
                                    {t('First_Name')}
                                </Form.Label>
                                <Form.Control type="text"
                                              name="firstName"
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                              defaultValue={formik.values.firstName}
                                />
                                {errors.firstName && touched.firstName && (
                                    <small className="text-danger">{errors.firstName}</small>
                                )}
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-4">
                                <Form.Label className="font-size-13 font-weight-bold">
                                    {t('Last_Name')}</Form.Label>
                                <Form.Control type="text" name="lastName" onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                              defaultValue={formik.values.lastName}/>
                                {errors.lastName && touched.lastName && (
                                    <small className="text-danger">{errors.lastName}</small>
                                )}
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-4">
                                <Form.Label className="font-size-13 font-weight-bold">{t('Email')}</Form.Label>
                                <Form.Control type="text" name="email" onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                              defaultValue={formik.values.email}/>
                                {errors.email && touched.email && (
                                    <small className="text-danger">{errors.email}</small>
                                )}
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-4">
                                <Form.Label
                                    className="font-size-13 font-weight-bold">{t('Telephone_Number')}</Form.Label>
                                <Form.Control type="text" name="phone" onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                              defaultValue={formik.values.phone}/>
                                {errors.phone && touched.phone && (
                                    <small className="text-danger">{errors.phone}</small>
                                )}
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-4">
                                <div className="form-group arrow-down">
                                    <Form.Label
                                        className="font-size-13 font-weight-bold">{t('Data_Format')}</Form.Label>
                                    <Form.Control as="select" name="dataFormat" onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  defaultValue={formik.values.dataFormat}>
                                        <option defaultValue="ENGLISH">
                                            MM/DD/YYYY
                                        </option>
                                        <option defaultValue="SPANISH">
                                            DD/MM/YYYY
                                        </option>
                                        {errors.dataFormat && touched.dataFormat && (
                                            <small className="text-danger">{errors.dataFormat}</small>
                                        )}
                                    </Form.Control>
                                    <ArrowDownIcon className="arrow-down-icon"/>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-4">
                                <div className="form-group arrow-down">
                                    <Form.Label className="font-size-13 font-weight-bold">{t('Language')}</Form.Label>
                                    <Form.Control as="select" name="language"
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  defaultValue={formik.values.language}>

                                        {Object.keys(languages).map((language) => (
                                            <option value={language}>
                                                {languages[language].nativeName}
                                            </option>
                                        ))
                                        }
                                    </Form.Control>
                                    <ArrowDownIcon className="arrow-down-icon"/>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="text-right">
                        <Button type="button" variant="primary" onClick={() => {
                            formik.handleSubmit();
                        }}>{t('Save')}</Button>
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
};

export default UserProfile;
